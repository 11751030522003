import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://lx.intelltai.com/mine/api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// API 错误处理
const handleError = (error) => {
  if (error.response) {
    // 服务器返回错误
    console.error('API Error:', error.response.data);
    throw new Error(error.response.data.message || '服务器错误');
  } else if (error.request) {
    // 请求未收到响应
    console.error('Network Error:', error.request);
    throw new Error('网络连接错误');
  } else {
    // 请求配置错误
    console.error('Request Error:', error.message);
    throw new Error('请求配置错误');
  }
};

// 仪表板数据 API
export const dashboardApi = {
  // 获取仪表板数据
  getDashboardData: async () => {
    try {
      const response = await api.get('/dashboard.php');
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  
  // 更新实时数据
  getRealtimeStats: async () => {
    try {
      const response = await api.get('/dashboard.php?type=realtime');
      return response.data;
    } catch (error) {
      handleError(error);
    }
  }
};

// 人员查询 API
export const taskApi = {
  // 查询人员列表
  queryTasks : async (params) => {
    try {
      const response = await api.get('/task_query.php', { params });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  // 获取人员详情
  getTransporterDetail: async (id) => {
    try {
      const response = await api.get(`/query.php?id=${id}`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  // 更新人员状态
  updateTransporterStatus: async (id, status) => {
    try {
      const response = await api.post('/query.php', {
        id,
        status,
        action: 'updateStatus'
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  }
};