import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const DetailPage = () => {
  const { id } = useParams(); // 获取动态参数
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold">详情页</h1>
      <p className="mt-4 text-gray-700">当前页面 ID：{id}</p>

      {/* 返回按钮 */}
      <button 
        onClick={() => navigate(-1)} 
        className="px-4 py-2 mt-6 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        返回
      </button>
    </div>
  );
};

export default DetailPage;
