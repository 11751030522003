import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  Clipboard,
  Filter, 
  MapPin, 
  Clock,
  AlertCircle,
  Loader,
  ArrowLeft,
  Calendar,
  Navigation // New icon for destination
} from 'lucide-react';
import { taskApi, dashboardApi } from '../services/api';


// Status enum
const TaskStatus = {
  PENDING: 1,    // Waiting for pickup
  IN_PROGRESS: 2, // In progress
  COMPLETED: 3,   // Completed
};

// Delivery status enum
const DeliveryStatus = {
  WAITING_PICKUP: 2,  // Waiting for pickup
  PICKED_UP: 3,       // Picked up
  DELIVERED: 4,       // Delivered
};

// Status text mapping
const TaskStatusText = {
  [TaskStatus.PENDING]: '待接单',
  [TaskStatus.IN_PROGRESS]: '进行中',
  [TaskStatus.COMPLETED]: '完成',
};

// Delivery status text mapping
const DeliveryStatusText = {
  [DeliveryStatus.WAITING_PICKUP]: '待接送',
  [DeliveryStatus.PICKED_UP]: '已接到',
  [DeliveryStatus.DELIVERED]: '已送达',
};

// Status badge component
const StatusBadge = ({ status }) => {
  const getStatusStyle = (status) => {
    switch (parseInt(status)) {
      case TaskStatus.PENDING:
        return 'bg-yellow-100 text-yellow-800';
      case TaskStatus.IN_PROGRESS:
        return 'bg-blue-100 text-blue-800';
      case TaskStatus.COMPLETED:
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusStyle(status)}`}>
      {TaskStatusText[status] || '未知状态'}
    </span>
  );
};



const FilterSection = ({ onFilterChange, filters, departments, destinations, isLoading }) => (
    <div className="bg-white p-4 rounded-lg shadow mb-6 flex flex-wrap gap-4">
      <div className="flex items-center">
        <Filter className="w-5 h-5 text-gray-500 mr-2" />
        <span className="text-gray-700">筛选：</span>
      </div>
      
      <select
        className="px-3 py-2 border rounded-lg text-gray-700 disabled:opacity-50"
        value={filters.status}
        onChange={(e) => onFilterChange('status', e.target.value)}
        disabled={isLoading}
      >
        <option value="">所有状态</option>
        <option value={TaskStatus.PENDING}>待接单</option>
        <option value={TaskStatus.IN_PROGRESS}>进行中</option>
        <option value={TaskStatus.COMPLETED}>完成</option>
      </select>
  
      <select
        className="px-3 py-2 border rounded-lg text-gray-700 disabled:opacity-50"
        value={filters.delivery_status}
        onChange={(e) => onFilterChange('delivery_status', e.target.value)}
        disabled={isLoading}
      >
        <option value="">所有接送状态</option>
        <option value={DeliveryStatus.WAITING_PICKUP}>待接送</option>
        <option value={DeliveryStatus.PICKED_UP}>已接到</option>
        <option value={DeliveryStatus.DELIVERED}>已送达</option>
      </select>

      <select
        className="px-3 py-2 border rounded-lg text-gray-700 disabled:opacity-50"
        value={filters.department}
        onChange={(e) => onFilterChange('department', e.target.value)}
        disabled={isLoading}
      >
        <option value="">所有科室</option>
        {departments.map(dept => (
          <option key={dept} value={dept}>{dept}</option>
        ))}
      </select>
  
      <select
        className="px-3 py-2 border rounded-lg text-gray-700 disabled:opacity-50"
        value={filters.destination}
        onChange={(e) => onFilterChange('destination', e.target.value)}
        disabled={isLoading}
      >
        <option value="">所有目的地</option>
        {destinations.map(dest => (
          <option key={dest} value={dest}>{dest}</option>
        ))}
      </select>
    </div>
  );

const TaskList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [filters, setFilters] = useState({
      status: searchParams.get('status') || '',
      delivery_status: searchParams.get('delivery_status') || '',
      department: searchParams.get('department') || '',
      destination: searchParams.get('destination') || '' // New destination filter
    });

  const [tasks, setTasks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dashboardStats, setDashboardStats] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [destinations, setDestinations] = useState([]); // New destination list

  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const tasksPerPage = 10; // Define the number of tasks per page

  const handleSortChange = (field) => {
    let order = 'asc';
    if (sortField === field && sortOrder === 'asc') {
      order = 'desc';
    }
    setSortField(field);
    setSortOrder(order);
  };
  




  // Fetch department list
  const fetchDepartments = async () => {
    try {
      const response = await fetch('http://lx.intelltai.com/mine/api/departments.php');
      if (!response.ok) {
        throw new Error('Failed to fetch departments');
      }
      const data = await response.json();
      setDepartments(data);
    } catch (err) {
      console.error('Error fetching departments:', err);
      setError('获取科室列表失败');
    }
  };

    // Fetch destination list
    const fetchDestinations = async () => {
        try {
          const response = await fetch('http://lx.intelltai.com/mine/api/destinations.php');
          if (!response.ok) {
            throw new Error('Failed to fetch destinations');
          }
          const data = await response.json();
          setDestinations(data);
        } catch (err) {
          console.error('Error fetching destinations:', err);
          setError('获取目的地列表失败');
        }
      };

  // Fetch task data
  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      if (filters.status) params.append('status', filters.status);
      if (filters.department) params.append('department', filters.department);
      if (filters.destination) params.append('destination', filters.destination);
      params.append('page', currentPage);
      params.append('limit', tasksPerPage);
      if (sortField) {
        params.append('sortField', sortField);
        params.append('sortOrder', sortOrder);
      }
  
      const response = await fetch(`http://lx.intelltai.com/mine/api/task_query.php?${params}`);
      if (!response.ok) {
        throw new Error('Failed to fetch tasks');
      }
      const data = await response.json();
      setTasks(data.tasks);
      setTotalPages(data.totalPages);
      setLastUpdated(new Date());
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTasks0 = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      if (filters.status) params.append('status', filters.status);
      if (filters.delivery_status) params.append('delivery_status', filters.delivery_status);
      if (filters.department) params.append('department', filters.department);
      if (filters.destination) params.append('destination', filters.destination);
      
      const response = await fetch(`http://lx.intelltai.com/mine/api/task_query.php?${params}`);
      if (!response.ok) {
        throw new Error('Failed to fetch tasks');
      }
      const data = await response.json();
      setTasks(data);
      setLastUpdated(new Date());
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  

  // Fetch dashboard stats
  const fetchDashboardStats = async () => {
    try {
      const stats = await dashboardApi.getRealtimeStats();
      setDashboardStats(stats);
    } catch (err) {
      console.error('Failed to fetch dashboard stats:', err);
    }
  };

  // Initialize data and set polling
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchDepartments(),
        fetchDestinations(),
        fetchTasks(),
        fetchDashboardStats()
      ]);
    };
  
    fetchData();
  
    const taskTimer = setInterval(fetchTasks, 30000);
    const statsTimer = setInterval(fetchDashboardStats, 60000);
  
    return () => {
      clearInterval(taskTimer);
      clearInterval(statsTimer);
    };
  }, [filters, currentPage, sortField, sortOrder]);
  

  const handleFilterChange = (filterType, value) => {
    const newFilters = {
      ...filters,
      [filterType]: value
    };
    
    // Update state
    setFilters(newFilters);
    
    // Update URL params
    const params = new URLSearchParams();
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value) {
        params.set(key, value);
      }
    });
    setSearchParams(params);
  };

  // Update task status
  const handleStatusUpdate = async (id, newStatus) => {
    try {
      const response = await fetch('/api/update_task_status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, status: newStatus }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to update task status');
      }
      
      await fetchTasks();
    } catch (err) {
      setError(`更新状态失败: ${err.message}`);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <div className="mb-6">
        <div className="flex items-center mb-4">
          <button
            onClick={() => window.location.href = '/'}
            className="mr-4 p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <ArrowLeft className="w-5 h-5 text-gray-600" />
          </button>
          <div>
            <h2 className="text-2xl font-bold text-gray-800">任务列表</h2>
            <p className="text-gray-600">
              
            </p>
          </div>
        </div>
        <p className="text-sm text-gray-500">
          最后更新: {lastUpdated.toLocaleTimeString()}
        </p>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
          <div className="flex items-center text-red-800">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span>{error}</span>
          </div>
        </div>
      )}

    <FilterSection 
        onFilterChange={handleFilterChange} 
        filters={filters}
        departments={departments}
        destinations={destinations}
        isLoading={isLoading}
      />

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSortChange('deliveryer')}
              >
                输送员 {sortField === 'deliveryer' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSortChange('status')}
              >
                状态 {sortField === 'status' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSortChange('buy_username')}
              >
                服务科室 {sortField === 'buy_username' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              >
                床号
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSortChange('accept_address')}
              >
                目的地 {sortField === 'accept_address' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              onClick={() => handleSortChange('yuyue_start_time')}
              >
                预约开始时间 {sortField === 'yuyue_start_time' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              onClick={() => handleSortChange('yuyue_end_time')}
              >
                预约结束时间 {sortField === 'yuyue_end_time' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSortChange('delivery_instore_time')}
              >
                接送时间 {sortField === 'delivery_instore_time' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSortChange('delivery_success_time')}
              >
                到达时间 {sortField === 'delivery_success_time' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
            </tr>
          </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tasks.map((task) => (
                <tr 
                  key={task.id}
                  className="hover:bg-gray-50 transition-colors"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <Clipboard className="h-10 w-10 rounded-full bg-gray-200 p-2" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">{task.deliveryer || '无'}</div>
                        <div className="text-sm text-gray-500">ID: {task.id}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <StatusBadge status={task.status} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center text-gray-900">
                      <MapPin className="w-4 h-4 mr-2 text-gray-500" />
                      {task.buy_username}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {task.chuanghao}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center text-gray-900">
                      <Navigation className="w-4 h-4 mr-2 text-gray-500" />
                      {task.accept_address || '未指定'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  {task.yuyue_start_time}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  {task.yuyue_end_time}
                  </td>
                  
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  {task.delivery_instore_time > 0?new Date(task.delivery_instore_time*1000).toLocaleString():0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  {task.delivery_success_time>0?new Date(task.delivery_success_time*1000).toLocaleString():0}
                  </td>
                </tr>
              ))}
              {tasks.length === 0 && !isLoading && (
                <tr>
                  <td colSpan="8" className="px-6 py-4 text-center text-gray-500">
                    暂无任务
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isLoading && (
          <div className="flex justify-center py-4">
            <Loader className="w-6 h-6 animate-spin text-gray-400" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskList;
