import React, { useState, useEffect } from 'react';
import { Activity, Ambulance, Clock, UserCheck, User, Building } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { dashboardApi } from '../services/api';
import DataTable from './DataTable.js';
import AlertDialog from '../components/ui/alert-dialog';

const Dashboard = () => {
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date());
  const [stats, setStats] = useState({
    activeTransports: 0,
    waitingTake: 0,
    waitingPatients: 0,
    completedToday: 0,
    overtimeTasks: 0,
    totalStaff: 0,
    departmentTasks: 0,
  });
  const [staffData, setStaffData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    id: null,
    title: '',
    message: '',
    timestamp: null
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const getDashboardData = async () => {
    try {
      setIsLoading(true);
      const data = await dashboardApi.getDashboardData();
      setStats({
        activeTransports: data.activeTransports,
        waitingTake: data.waitingTake,
        waitingPatients: data.waitingPatients,
        completedToday: data.completedToday,
        overtimeTasks: data.overtimeTasks,
        totalStaff: data.totalStaff,
        departmentTasks: data.departmentTasks,
      });
      setStaffData(data.staffData);
      setLastUpdated(new Date());

      if (data.emergencyAlert) {
        setAlertData({
          id: data.emergencyAlert.id,
          title: data.emergencyAlert.title,
          message: data.emergencyAlert.message,
          timestamp: data.emergencyAlert.timestamp
        });
        setAlertOpen(true);
      }

      setError(null);
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
      setError('无法获取仪表板数据');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
    const interval = setInterval(() => {
      getDashboardData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const StatCard = ({ Icon, title, value, bgColor, textColor, onClick }) => (
    <div
      className={`${bgColor} rounded-xl shadow-xl p-6 cursor-pointer hover:opacity-90 opacity-75`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <Icon size={48} className={`${textColor} mr-4`} />
        <div>
          <div className={`${textColor} text-lg`}>{title}</div>
          <div className="text-white text-3xl font-bold">{value}</div>
        </div>
      </div>
    </div>
  );

  const statCardsConfig = [
    { 
      Icon: Clock, 
      title: '待接单', 
      value: stats.waitingTake, 
      bgColor: 'bg-orange-900', 
      textColor: 'text-orange-400', 
      link: '/task-list?status=1' 
    },
    { 
      Icon: Clock, 
      title: '待输送', 
      value: stats.waitingPatients, 
      bgColor: 'bg-teal-900', 
      textColor: 'text-teal-400', 
      link: '/task-list?status=2' 
    },
    { 
      Icon: Ambulance, 
      title: '输送中', 
      value: stats.activeTransports, 
      bgColor: 'bg-blue-900', 
      textColor: 'text-blue-400', 
      link: '/task-list?status=2&delivery_status=3' 
    },
    { 
      Icon: UserCheck, 
      title: '今日完成', 
      value: stats.completedToday, 
      bgColor: 'bg-green-900', 
      textColor: 'text-green-400', 
      link: '/task-list?status=3' 
    },
    { 
      Icon: User, 
      title: '在岗人员', 
      value: stats.totalStaff, 
      bgColor: 'bg-purple-900', 
      textColor: 'text-purple-400', 
      link: '/transporter-list' 
    },
    { 
      Icon: Building, 
      title: '科室任务分布图', 
      value: stats.departmentTasks, 
      bgColor: 'bg-indigo-900', 
      textColor: 'text-indigo-400', 
      link: '/department-dashboard' 
    }
  ];

  return (
    <div className="min-h-screen bg-cover bg-center p-6" style={{ backgroundImage: 'url(/bg.jpg)' }}>
      <AlertDialog 
        open={alertOpen}
        onOpenChange={setAlertOpen}
        title={alertData.title}
        message={alertData.message}
        timestamp={alertData.timestamp}
        alertId={alertData.id}
      />

      {isLoading ? (
        <div className="text-white text-center text-2xl">加载中...</div>
      ) : error ? (
        <div className="text-red-400 text-center text-2xl">{error}</div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-white text-4xl font-bold">病患输送调度系统</h1>
            <div className="text-yellow-400 text-2xl font-semibold">
              {currentTime.toLocaleTimeString('zh-CN', { hour12: false })}
            </div>
          </div>

          {lastUpdated && (
            <div className="text-white text-lg mb-4">
              最后更新时间: {lastUpdated.toLocaleTimeString('zh-CN', { hour12: false })}
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {statCardsConfig.map((card, index) => (
              <StatCard
                key={index}
                Icon={card.Icon}
                title={card.title}
                value={card.value}
                bgColor={card.bgColor}
                textColor={card.textColor}
                onClick={() => navigate(card.link)}
              />
            ))}
          </div>

          <div className="bg-gray-800 rounded-xl p-6 opacity-75">
            <h2 className="text-white text-xl font-semibold mb-4">输送员位置和任务信息</h2>
            <DataTable staffData={staffData} />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;