import React, { useEffect, useState, useRef } from 'react';
import { Loader2 } from 'lucide-react';
import { Card, CardContent } from './ui/card';

const DataTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const scrollContainerRef = useRef(null);
  const autoScrollIntervalRef = useRef(null);
  
  const statusArray = ['状态', '接单中', '输送中', '休息', '离线'];
  const statusColors = {
    1: 'bg-green-100 text-green-800',
    2: 'bg-blue-100 text-blue-800',
    3: 'bg-yellow-100 text-yellow-800',
    4: 'bg-gray-100 text-gray-800'
  };

  const safeDecodeString = (str) => {
    try {
      if (str && typeof str === 'string') {
        return str;
      }
      return '未知';
    } catch (e) {
      console.error('Decode error:', e);
      return '未知';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://lx.intelltai.com/deliveryer_location_list.php');
        const result = await response.json();
        
        if (Array.isArray(result)) {
          const processedData = result.map(item => ({
            ...item,
            title: safeDecodeString(item.title),
            location: item.location || '暂无位置信息',
            status: Number(item.status)
          }));
          setData(processedData);
        } else {
          setData([]);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 120000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer || loading || data.length === 0) return;

    const startAutoScroll = () => {
      if (autoScrollIntervalRef.current) {
        clearInterval(autoScrollIntervalRef.current);
      }

      autoScrollIntervalRef.current = setInterval(() => {
        if (!isPaused && scrollContainer) {
          const rowHeight = 41;
          const currentScroll = scrollContainer.scrollTop;
          const maxScroll = scrollContainer.scrollHeight - scrollContainer.clientHeight;

          if (currentScroll >= maxScroll) {
            scrollContainer.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          } else {
            scrollContainer.scrollTo({
              top: currentScroll + rowHeight,
              behavior: 'smooth'
            });
          }
        }
      }, 3000);
    };

    startAutoScroll();

    return () => {
      if (autoScrollIntervalRef.current) {
        clearInterval(autoScrollIntervalRef.current);
      }
    };
  }, [data, loading, isPaused]);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  return (
    <Card className="bg-gray-900">
      <CardContent className="p-6">
        {loading ? (
          <div className="flex items-center justify-center h-48">
            <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
          </div>
        ) : (
          <div 
            ref={scrollContainerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="h-[480px] overflow-y-auto rounded-md border border-gray-800 scroll-smooth"
          >
            <table className="w-full text-sm">
              <thead className="bg-gray-800 sticky top-0 z-10">
                <tr>
                  <th className="px-4 py-3 text-left text-gray-400 font-medium">位置</th>
                  <th className="px-4 py-3 text-left text-gray-400 font-medium">科室</th>
                  <th className="px-4 py-3 text-left text-gray-400 font-medium">输送员</th>
                  <th className="px-4 py-3 text-left text-gray-400 font-medium">状态</th>
                  <th className="px-4 py-3 text-left text-gray-400 font-medium">任务状态</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {data.length > 0 ? (
                  data.map((row, index) => (
                    <tr
                      key={index}
                      onClick={() => window.open(`http://lx.intelltai.com/history.php?id=${row.userId}&name=${encodeURIComponent(row.title)}`)}
                      className="hover:bg-gray-800/50 cursor-pointer transition-colors"
                    >
                      <td className="px-4 py-3">
                        <span className={row.location === '暂无位置信息' ? 'text-gray-500 italic' : 'text-gray-300'}>
                          {row.location}
                        </span>
                      </td>
                      <td className="px-4 py-3">
                        <span className="text-white font-medium">
                          {row.store_name}
                        </span>
                      </td>
                      <td className="px-4 py-3">
                        <span className="text-white font-medium">
                          {row.title}
                        </span>
                      </td>
                      <td className="px-4 py-3">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusColors[row.status] || 'bg-gray-700 text-gray-300'}`}>
                          {statusArray[row.status] || '未知状态'}
                        </span>
                      </td>
                      <td className="px-4 py-3">
                        <span className="text-white font-medium">
                          {row.task_finished + '/' + row.task_total || '未知状态'}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="px-4 py-8 text-center text-gray-400">
                      暂无数据
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default DataTable;