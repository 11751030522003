import React, { useState, useEffect } from 'react';
import { 
  Clipboard,
  Filter, 
  AlertCircle,
  Loader,
  ArrowLeft,
  User,
  ChevronUp,
  ChevronDown,
  ChevronsLeft,
  ChevronsRight
} from 'lucide-react';

const DeliveryerStatus = {
  ACTIVE: 1,
  INACTIVE: 0,
};

const DeliveryerStatusText = {
  [DeliveryerStatus.ACTIVE]: '活跃',
  [DeliveryerStatus.INACTIVE]: '不活跃',
};

const ITEMS_PER_PAGE = 10;



const DeliveryerStatusBadge = ({ status }) => {
  const getStatusStyle = (status) => {
    switch (parseInt(status)) {
      case DeliveryerStatus.ACTIVE:
        return 'bg-green-100 text-green-800';
      case DeliveryerStatus.INACTIVE:
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusStyle(status)}`}>
      {DeliveryerStatusText[status] || '未知状态'}
    </span>
  );
};

const DeliveryerFilterSection = ({ onFilterChange, filters, isLoading }) => (
  <div className="bg-white p-4 rounded-lg shadow mb-6 flex flex-wrap gap-4">
    <div className="flex items-center">
      <Filter className="w-5 h-5 text-gray-500 mr-2" />
      <span className="text-gray-700">筛选：</span>
    </div>
    
    <select
      className="px-3 py-2 border rounded-lg text-gray-700 disabled:opacity-50"
      value={filters.status}
      onChange={(e) => onFilterChange('status', e.target.value)}
      disabled={isLoading}
    >
      <option value="">所有状态</option>
      <option value={DeliveryerStatus.ACTIVE}>活跃</option>
      <option value={DeliveryerStatus.INACTIVE}>不活跃</option>
    </select>
  </div>
);

const DeliveryerList = () => {
  // 获取URL参数的初始值
  const getInitialFilters = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      status: params.get('status') || '',
    };
  };
  
  const [filters, setFilters] = useState(getInitialFilters());
  const [deliveryers, setDeliveryers] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  // 分页状态
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  // 排序状态
  const [sortConfig, setSortConfig] = useState({
    key: 'id',
    direction: 'desc'
  });

  const fetchDeliveryers = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      if (filters.status) params.append('status', filters.status);
      params.append('page', currentPage);
      params.append('per_page', ITEMS_PER_PAGE);
      params.append('sort_by', sortConfig.key);
      params.append('sort_direction', sortConfig.direction);
      
      const response = await fetch(`http://lx.intelltai.com/mine/api/deliveryer_query.php?${params}`);
      if (!response.ok) throw new Error('Failed to fetch deliveryers');
      const data = await response.json();
      setDeliveryers(Array.isArray(data.items) ? data.items : []);
      setTotalPages(Math.ceil(data.total / ITEMS_PER_PAGE));    
      setTotal(data.total);  
      setLastUpdated(new Date());
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDeliveryers();
    const deliveryerTimer = setInterval(fetchDeliveryers, 30000);
    return () => clearInterval(deliveryerTimer);
  }, [filters, currentPage, sortConfig]);

  const handleFilterChange = (filterType, value) => {
    const newFilters = {
      ...filters,
      [filterType]: value
    };
    setFilters(newFilters);
    setCurrentPage(1); // 重置到第一页
    
    const params = new URLSearchParams(window.location.search);
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });
    
    window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
  };

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const SortHeader = ({ column, label }) => (
    <th
      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
      onClick={() => handleSort(column)}
    >
      <div className="flex items-center">
        {label}
        {sortConfig.key === column && (
          <span className="ml-2">
            {sortConfig.direction === 'asc' ? 
              <ChevronUp className="w-4 h-4" /> : 
              <ChevronDown className="w-4 h-4" />
            }
          </span>
        )}
      </div>
    </th>
  );

  const Pagination = () => (
    <div className="flex items-center justify-between px-6 py-3 bg-white border-t">
      <div className="flex items-center">
        <span className="text-sm text-gray-700">
          第 {currentPage} 页，共 {totalPages} 页
        </span>
      </div>
      <div className="flex space-x-2">
        <button
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
          className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
        >
          <ChevronsLeft className="w-5 h-5" />
        </button>
        <button
          onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 rounded hover:bg-gray-100 disabled:opacity-50"
        >
          上一页
        </button>
        <button
          onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
          disabled={currentPage === totalPages}
          className="px-3 py-1 rounded hover:bg-gray-100 disabled:opacity-50"
        >
          下一页
        </button>
        <button
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
          className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
        >
          <ChevronsRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );

  return (
    <div className="bg-gray-100 p-6">
      <div className="mb-6">
        <div className="flex items-center mb-4">
          <button
            onClick={() => window.location.href = '/'}
            className="mr-4 p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <ArrowLeft className="w-5 h-5 text-gray-600" />
          </button>
          <div>
            <h2 className="text-2xl font-bold text-gray-800">输送员列表</h2>
            <p className="text-gray-600">共 {total} 个输送员</p>
          </div>
        </div>
        <p className="text-sm text-gray-500">
          最后更新: {lastUpdated.toLocaleTimeString()}
        </p>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
          <div className="flex items-center text-red-800">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span>{error}</span>
          </div>
        </div>
      )}

      <DeliveryerFilterSection 
        onFilterChange={handleFilterChange} 
        filters={filters}
        isLoading={isLoading}
      />

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <SortHeader column="id" label="输送员" />
                <SortHeader column="status" label="科室" />
                <SortHeader column="status" label="状态" />
                <SortHeader column="mobile" label="联系电话" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {deliveryers.map((deliveryer) => (
                <tr 
                  key={deliveryer.id}
                  className="hover:bg-gray-50 transition-colors"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <User className="h-10 w-10 rounded-full bg-gray-200 p-2" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">{deliveryer.nickname || '无'}</div>
                        <div className="text-sm text-gray-500">ID: {deliveryer.id}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {deliveryer.store_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <DeliveryerStatusBadge status={deliveryer.status} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{deliveryer.mobile || '未提供'}</div>
                  </td>
                </tr>
              ))}
              {deliveryers.length === 0 && !isLoading && (
                <tr>
                  <td colSpan="3" className="px-6 py-4 text-center text-gray-500">
                    暂无输送员
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isLoading && (
          <div className="flex justify-center py-4">
            <Loader className="w-6 h-6 animate-spin text-gray-400" />
          </div>
        )}
      </div>
      <Pagination />
    </div>
  );
};

export default DeliveryerList;