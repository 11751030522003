import React from "react";
import { AlertTriangle } from "lucide-react";

export default function AlertDialog({
  open,
  onOpenChange,
  title = "紧急通知",
  message = "",
  timestamp = null,
  alertId = null,
}) {
  const handleConfirm = async () => {
    try {
      const response = await fetch('http://lx.intelltai.com/mine/api/update_alert_status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ alertId }),
      });

      const data = await response.json();
      
      if (data.success) {
        onOpenChange(false);
      } else {
        console.error('更新消息状态失败:', data.message);
        // 可以选择是否关闭弹窗
        onOpenChange(false);
      }
    } catch (error) {
      console.error('更新消息状态出错:', error);
      // 发生错误时也可以选择关闭弹窗
      onOpenChange(false);
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black/50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4 overflow-hidden">
        {/* Header */}
        <div className="p-6">
          <div className="flex items-center gap-2 text-xl font-semibold text-red-600 mb-4">
            <AlertTriangle className="h-6 w-6" />
            <h2>{title}</h2>
          </div>
          
          {/* Content */}
          <div className="space-y-4">
            <p className="text-gray-900 text-base">
              {message}
            </p>
            {timestamp && (
              <p className="text-sm text-gray-500">
                通知时间: {new Date(timestamp).toLocaleString('zh-CN')}
              </p>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="border-t border-gray-100 p-4 bg-gray-50 flex justify-end gap-2">
          <button
            onClick={() => onOpenChange(false)}
            className="px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 rounded"
          >
            关闭
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 rounded"
          >
            确认
          </button>
        </div>
      </div>
    </div>
  );
}