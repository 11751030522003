import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './DepartmentDashboard.css';

const DepartmentDashboard = () => {
  const [timeRange, setTimeRange] = useState('day');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`http://lx.intelltai.com/mine/api/departmentdash.php?timeRange=${timeRange}`);
        const result = await response.json();
        if (result.error) throw new Error(result.error);
        setData(result.departments);
      } catch (err) {
        setError('数据加载失败');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [timeRange]);

  return (
    <div className="p-6 min-h-screen bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg w-full">
        <div className="p-4 flex items-center justify-between border-b">
          <h2 className="text-xl font-bold">科室任务统计</h2>
          <div className="flex gap-2">
            <button
              className={`px-4 py-2 rounded-lg ${timeRange === 'day' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => setTimeRange('day')}
            >
              当天
            </button>
            <button
              className={`px-4 py-2 rounded-lg ${timeRange === 'month' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => setTimeRange('month')}
            >
              当月
            </button>
          </div>
        </div>
        <div className="p-4 h-[600px]">
          {loading ? (
            <div className="flex items-center justify-center h-full">加载中...</div>
          ) : error ? (
            <div className="flex items-center justify-center h-full text-red-500">{error}</div>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
  <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis 
      dataKey="department" 
      label={{ value: '科室', position: 'insideBottom', offset: -5 }}
      tick={{ fontSize: 12 }} // 设置字体大小
    />
    <YAxis label={{ value: '任务数量', angle: -90, position: 'insideLeft' }} />
    <Tooltip formatter={(value) => [`${value} 个任务`, '总任务']} />
    <Bar dataKey="tasks" fill="#1890ff" name="总任务" />
  </BarChart>
</ResponsiveContainer>

          )}
        </div>
      </div>
    </div>
  );
};

export default DepartmentDashboard;