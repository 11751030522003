// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import TaskList from './components/TaskList';
import TransporterList from './components/TransporterList';

import TransporterDetails from './components/TransporterDetails'; // Import TransporterDetails
import DepartmentDashboard from './components/DepartmentDashboard';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Task-list" element={<TaskList />} />
        <Route path="/Transporter-list" element={<TransporterList />} />
        <Route path="/transporter/:id" element={<TransporterDetails />} />
        <Route path="/Department-Dashboard" element={<DepartmentDashboard />} />
      </Routes>
    </Router>
  );
}

export default App;